import React, { ReactElement } from "react"

// import Bio from "../components/bio"
// import SEO from "../components/seo"

import { BasePage } from '../components/Base Page'
import { ContentCard } from "../components/Content Card/ContentCard";

interface CarePageState {
}

class CarePage extends React.Component<{}, CarePageState> {
  public static readonly displayName = "CarePage";

  constructor(p) {
    super(p);
    this.state = {};
  }

  public render() {
    var k = 1
    return (
      <BasePage key={(k++).toString()}
        style={{maxWidth:"800px", marginLeft: "auto", marginRight: "auto"}}
        children={[
          <ContentCard key={(k++).toString()} children={[
            {type: "Heading2", content: `Vacuum`},
            {type: "Text", content: `Vacuuming is the easiest way to keep your Skrug clean.`},
            {type: "Text", content: 
              <p key={(k++).toString()}>
                Be sure to use a suction vacuum only...
                <i key={(k++).toString()} className="Highlight-Yellow">Do NOT use the spinny brush thing </i> 
                found on most large vacuums (it's called a beater bar). The spinning bristles can twist, fray, or break fibers, and may reduce the condition of your Skrug! 
              </p>
            },

            {type: "Heading2", content: `Stains`},
            {type: "Text", content: `For crusty / dry stains, slowly scrape and vacuum away the stain.`},
            {type: "Text", content: <p key={(k++).toString()}>For wet stains, start with a paper towel to absorb the stain. Use lukewarm water and a cloth or sponge to dampen the stain, soak up the water with a cloth. Repeat until the stain fades. <i key={(k++).toString()} className="Highlight-Yellow">Do NOT super soak the rug</i> as that may affect glue and tuft quality.</p>},
            {type: "Text", content: `For greasy stains, try using a ph-neutral / bleach free cleaner. `},
            
            {type: "Heading2", content: `When in doubt...`},
            {type: "Text", content:"Get your rug cleaned professionally!!!!!!!"},
            {type: "Text", content: <p key={(k++).toString()}> <i key={(k++).toString()} className="Highlight-Yellow"> Do NOT dry clean! </i></p>},
          ]} />
        ]}
        currentSection="CARE"
      />
    )
  }
}

export default CarePage;
